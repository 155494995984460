// @ts-strict-ignore
import HttpCodes from 'http-status-codes';
import _ from 'lodash';
import { getAxiosInstance } from '@/requests/axios.utilities';

export function conflictRetryInterceptor(error) {
  const MAXIMUM_RETRIES = 5;
  if (error) {
    if (_.get(error, 'response.status') !== HttpCodes.CONFLICT) {
      return Promise.reject(error);
    }

    error.response.config.retry = error.response.config.retry || 0;

    if (error.response.config.retry < MAXIMUM_RETRIES) {
      error.response.config.retry++;
      return Promise.resolve().then(() => getAxiosInstance().request(error.response.config));
    } else {
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
}
