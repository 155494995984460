import React from 'react';
import Highcharts, { SeriesLineOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost.js';
import { formatTime } from '@/datetime/dateTime.utilities';
import { getXYRegion, setScatterPlotExtremes } from '@/utilities/chartHelper.utilities';
import { XyPlotChartIdsInterface } from '@/scatterPlot/XyPlotChartWrapper.organism';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import { Timezone } from '@/datetime/timezone.service';
import { MIN_SAMPLES_FOR_BOOST, SeeqScatterPlotPoint, XYPlotRegion } from '@/scatterPlot/scatterPlot.constants';

export interface ScatterPlotChartProps {
  viewRegion: XYPlotRegion;
  timezone: Timezone;
  setSelectedRegion: (region: XYPlotRegion) => void;
  fetchScatterPlot: () => void;
  setChart: (chart: Highcharts.Chart) => void;
  onMouseOverRef: any;
  setOnMouseOverRef: (chartData: XyPlotChartIdsInterface) => void;
  isContent?: boolean;
}

export interface ScatterPlotChartExternalProps
  extends Omit<ScatterPlotChartProps, 'setChart' | 'onMouseOverRef' | 'setOnMouseOverRef'> {}

boost(Highcharts);

export const ScatterPlotChart: React.FunctionComponent<ScatterPlotChartProps> = ({
  viewRegion,
  timezone,
  setSelectedRegion,
  isContent = false,
  onMouseOverRef,
  setOnMouseOverRef,
  setChart,
}) => {
  /**
   * Creates the initial scatter plot chart configuration to feed to Highcharts. The options set here will either
   * persist through the chart's lifetime, or get replaced when the chart gets updated.
   */
  const getInitialChartConfig = (): Highcharts.Options => ({
    boost: {
      // CRAB-29174 - highcharts bug - workaround found in (https://github.com/highcharts/highcharts/issues/8945)
      allowForce: false,
    },
    chart: {
      backgroundColor: 'transparent',
      animation: false,
      type: 'scatter',
      zoomType: isContent ? undefined : 'xy',
      alignTicks: false,
      events: {
        selection(e) {
          setSelectedRegion(getXYRegion(e.xAxis[0], e.yAxis));
          return false;
        },
        load() {
          // wait until all the charts data are set in order to be able to set extremes
          setTimeout(() => {
            setScatterPlotExtremes(this.xAxis[0], this.yAxis, viewRegion);
          }, 1);
        },
      },
    },
    plotOptions: {
      series: {
        animation: false,
        turboThreshold: 0,
        states: {
          hover: {
            lineWidthPlus: 0,
          },
          inactive: {
            enabled: false,
          },
        },
      },
      scatter: {
        boostThreshold: MIN_SAMPLES_FOR_BOOST,
        marker: {
          radius: 3,
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)',
            },
          },
        },
        point: {
          events: { mouseOver: onMouseOverRef.current },
        },
        tooltip: {
          headerFormat: '',
          pointFormatter() {
            const userOptions = this.series.userOptions;
            const xSignalName = userOptions.xSignalName;
            const ySignalName = userOptions.ySignalName;
            const xFormatted = formatNumber(this.x, {
              format: userOptions.xNumberFormat,
            });
            const yFormatted = formatNumber(this.y, {
              format: userOptions.yNumberFormat,
            });
            const time = (this as SeeqScatterPlotPoint).time;
            const timeFormatted = formatTime(time, timezone);
            return (
              `${timeFormatted}<br/>` +
              `${xSignalName}: <strong>${xFormatted}</strong><br/>` +
              `${ySignalName}: <strong>${yFormatted}</strong>`
            );
          },
        },
      },
      line: {
        boostThreshold: 0,
        lineWidth: 1,
        marker: { enabled: false },
        point: {
          events: { mouseOver: onMouseOverRef.current },
        },
        states: {
          hover: {
            halo: { size: 0 },
            lineWidthPlus: 0,
          },
        },
        animation: false,
        tooltip: {
          headerFormat: '',
          pointFormatter() {
            const userOptions = this.series.userOptions;
            const xFormatted = formatNumber(this.x, {
              format: userOptions.xNumberFormat,
            });
            const yFormatted = formatNumber(this.y, {
              format: (userOptions as SeriesLineOptions).numberFormat,
            });
            return `x: <strong>${xFormatted}</strong><br/>y: <strong>${yFormatted}</strong><br/>`;
          },
        },
      },
    },
    legend: { enabled: false },
    credits: { enabled: false },
    title: { text: undefined },
    xAxis: {
      title: {
        text: '',
      },
      lineWidth: 1,
      gridLineWidth: 1,
    },
    yAxis: [
      {
        title: {
          text: '',
        },
        // The default tick behavior (for the y-axis only) causes the axis to snap extremes to the nearest tick every
        // time you change it, which causes very weird behavior when panning and zooming on it
        startOnTick: false,
        endOnTick: false,
        lineWidth: 1,
        gridLineWidth: 1,
      },
    ],
  });

  /**
   * Callback method to be passed into the HighchartsReact object so we have access to the Highcharts Chart
   *
   * @param highchartsChart - the chart object from Highcharts
   */
  const afterChartCreated = (highchartsChart: Highcharts.Chart) => {
    setChart(highchartsChart);
    setOnMouseOverRef({});
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getInitialChartConfig()}
      containerProps={{ className: 'fullHeight' }}
      // Don't let the chart auto-update because we have to handle updates in a custom way (see updateChart())
      allowChartUpdate={false}
      callback={afterChartCreated}
    />
  );
};
